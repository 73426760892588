import React, { useEffect, useState, useLayoutEffect} from "react"
import DropdownInput from "../../Filters/DropdownInput"
import MessagesModal from "../../MessagesModal"

import ImgBuilder from "../../../res/img/imgBuilder"
import { observer, inject } from "mobx-react";
import { amountFilterCategories } from "../../../res/consts/filtersVariants"
import { closeDateFilterCategories } from "../../../res/consts/filtersVariants"
import { forecastFilterCategories } from "../../../res/consts/filtersVariants"
import MessagesPreview from "../../MessagesPreview"
import ChatButton from "../../ChatButton"
import useChatSocket from "../../../sockets/chat"
import useAllChatSocket from "../../../sockets/allChats";
import NeedToRefreshAlert from "../../NeedToRefreshAlert";

import "./styles.scss"
import { useRef } from "react";
import { chatsModel } from "../../../models/chats";
import { useNavigate } from "react-router-dom"

const DealDashboardMainTable = (props) =>{
    const {
        opportunitiesModel: {
            getOpportunitiesList, 
            opportunitiesList, 
            setCurrentOppotunity,
            opportunitiesListCommited,
            opportunitiesListExpected,
            opportunitiesListUpside,
            opportunitiesListPipeline,
            currentOppotunity,
            checkPushState,
            setCheckPushState,
        },
        objectivesModel:{
            setDiagramsData,
            currentObjective
        },
        chatsModel : {
            currentChatDetails,
            chatsList,
            getCurrentChatDetails,
            getCurrentChatMessagesData,
            getChatsList,
            currentChatMessagesData,
            setOpenedChat,
            openedChat,
            setChatUploadedMessages, 
            isTyping,
            setTypingStatus,
            createChat,
            chatsWithUnreadedMessages,
            setChatsWithUnreadedMessages,
            setClickNotificationUserName,
            clickNotificationUserName,
            setCurrentChatDetails,
        },
        authModel:{
            currenManager,
            tableSizeParams,
            setTableSizeParams
        },
        userModel : {
            userListIds,
            filteredUser,
            showPreview,
            needToRefresh,
            setNeedToRefresh
        },
        serverAPImodel:{baseAPI}
    } = props

    const [editedOrderList, setEditedOrderList] = useState(opportunitiesList)
    const [dateTypeSort, setDateTypeSort] = useState("Old first")
    const [amountTypeSort, setAmountTypeSort] = useState("Not selected")
    const [forcastType, setForcastType] = useState(localStorage.getItem("forcastType") ? localStorage.getItem("forcastType") : "All")
    const [totalCommited, setTotalCommited] = useState(editedOrderList.reduce((total, company) => {return total + company.amount},0))
    const [showMessagePreviewId, setShowMessagePreviewId] = useState(
        (Object.keys(currentChatMessagesData).length && !!localStorage.getItem("currentOppotunityId")) ? 
        localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1):
        null
    )
    //const [tableWidth, setTableWidth] = useState(0)
    const [openModalObject, setOpenModalObject] = useState({show:false})
    const [openBigModal, setOpenBigModal] = useState(false)
    const [customStyles, setCustomStyles] = useState({})
    const [activeItem, setActiveItem ] = useState('');
    const [chatsWithUnreadedState, setChatsWithUnreadedState] = useState([])
    const [setterCurrentOppAlreadyUsed, updateSetterCurrentOppAlreadyUsed] = useState(false)
    //const [needToRefresh, setNeedToRefresh] = useState(false)
    const tableRef = useRef(null)

    let wsLink = ""
    switch(baseAPI){
        case ("https://spotlogicunit4.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicunit4.pp.ua/cable/"
            break
        case ("https://qa.spotlogic.site/api/v1/"):
            wsLink = "wss://qa.spotlogic.site/cable/"
            break
        case ("https://xerox.pp.ua/api/v1/"):
            wsLink = "wss://xerox.pp.ua/cable/"
            break
        case ("https://spotlogic-mlogica.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-mlogica.pp.ua/cable/"
            break
        case ("https://netappspotlogic.pp.ua/api/v1/"):
            wsLink = "wss://netappspotlogic.pp.ua/cable/"
            break
        case ("https://hashicorp.spotlogic.site/api/v1/"):
            wsLink = "wss://hashicorp.spotlogic.site/cable/"
            break
        case ("https://solrenewables.spotlogic.site/api/v1/"):
            wsLink = "wss://solrenewables.spotlogic.site/cable/"
            break
        case ("https://scholarpath.spotlogic.site/api/v1/"):
            wsLink = "wss://scholarpath.spotlogic.site/cable/"
            break
    }

    useEffect(() => {
        setClickNotificationUserName(activeItem);
    }, [activeItem]);

    const socket = useAllChatSocket(wsLink, false, ()=>{});

    const parseSocketMessage = (message) => {
        const data = JSON.parse(message.data);
        if(data.type === "ping"){
            return;
        }else if(data?.message?.message){  
            if((data?.message?.chat_id !== currentChatDetails?.id) || 
                (!!localStorage.getItem("currentObjectiveId") && (data?.message?.chat_id === currentChatDetails?.id))
            ){
                let copyChatsWithUnreadedMessages = chatsWithUnreadedMessages
                !chatsWithUnreadedMessages.includes(data?.message?.chat_id) && copyChatsWithUnreadedMessages.push(data?.message?.chat_id)
                setChatsWithUnreadedMessages(copyChatsWithUnreadedMessages);
                setChatsWithUnreadedState(copyChatsWithUnreadedMessages)              
            }
        }
    }
   
    useEffect(() => {
        if(socket){
            socket.onmessage = (message) => {
                parseSocketMessage(message);
            }
        }
    }, [socket, currentChatDetails])



    useEffect(() => {
        let opportunitiesArray = opportunitiesList.map(opp => opp.id)
        let newChatsOpportunities = opportunitiesArray.filter((opp => !chatsList.some((chat => chat?.opportunity_id === opp))))
        if(!!newChatsOpportunities.length){
            newChatsOpportunities.filter(opp => createChat(opp))
        }
         
        if(localStorage.getItem("currentObjectiveId")){
            localStorage.removeItem("currentObjectiveId")
            let copyChatsWithUnreadedMessages = chatsWithUnreadedMessages.filter(item => item !== currentChatDetails?.id)
            setChatsWithUnreadedMessages(copyChatsWithUnreadedMessages)
        }
    }, [])

    const handleCloseMessagePreview = () => {
        if(isTyping){
            setTypingStatus(currentChatDetails.id, false)
        }
        setShowMessagePreviewId(null)
        setOpenedChat(false)
        let newOpenModalObject = {show: false}
        setOpenModalObject(newOpenModalObject);
        setOpenBigModal(false);
    }

    const handleShowMessagePreview = async (opportunityId) => {
        setOpenedChat(true)
        let x = await getCurrentChatDetails(opportunityId)
        if(x){
        
            setShowMessagePreviewId(opportunityId)

            let newOpenModalObject = {show:false, opportunityId: opportunityId}

            setOpenModalObject(newOpenModalObject)
        }
        localStorage.setItem("openedChat", JSON.stringify(true))
        //customHandleMessages()
    }

    const handleShowMessageModal = (opportunityId) => {
        getCurrentChatDetails(opportunityId)
        let newOpenModalObject = {show:true, opportunityId: opportunityId}
        setOpenModalObject(newOpenModalObject)
        setOpenedChat(true)
        localStorage.setItem("openedChat", JSON.stringify(true))
    };

    useEffect(() => {
        if(editedOrderList.length){
            let storagedCurrentUser = localStorage.getItem("currentOppotunityId") ? 
                (localStorage.getItem("currentOppotunityId").includes('"') ? localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1) :  localStorage.getItem("currentOppotunityId"))
                : 0
            let filteredData = editedOrderList.filter(opportunity => opportunity.id === storagedCurrentUser)
            let result = (storagedCurrentUser && filteredData.length) ?
                filteredData[0]:
                editedOrderList[0]
            setTotalCommited(editedOrderList.reduce((total, company) => {return total + company.amount},0))
            !setterCurrentOppAlreadyUsed && setCurrentOppotunity({company:result}); // Гарик, если баг будет связан с этой строкой - напиши Антону   
        }else{
            setDiagramsData([])
        }
        setNeedToRefresh(false)
    }, [editedOrderList]);

    useEffect(() => {
        if(tableRef){
          let tableWidth = tableRef.current.clientWidth
          let tableHeight = tableRef.current.clientHeight
          setTableSizeParams({width: tableWidth, height: tableHeight})
        }  
      }, [tableRef])

    useEffect(() => {
        
        if (tableRef) {
            let tableWidth = tableRef.current.clientWidth
            const currentChat = editedOrderList.find(item => item?.opp_company.name === clickNotificationUserName?.company_name)
            if (currentChat && tableWidth && checkPushState && Object.keys(clickNotificationUserName).length) {
                if (openBigModal.show) {
                    setOpenBigModal(false);
                    setOpenModalObject({show: false});
                    setCurrentOppotunity(null);    
                    setCurrentChatDetails({});                
                }
                setOpenBigModal(false);
                getCurrentChatMessagesData(clickNotificationUserName.chat_id);
                // setClickNotificationUserName({});
                setCheckPushState(false);
                if (tableWidth && tableWidth > 961) {
                    setOpenBigModal(true);
                }
                setOpenModalObject({show: true, opportunityId: currentChat?.company_id});
                setCurrentOppotunity({company: currentChat});
                updateSetterCurrentOppAlreadyUsed(true)
                setCurrentChatDetails({id: clickNotificationUserName.chat_id});
            }
        }
    }, [checkPushState, editedOrderList, tableRef, clickNotificationUserName]);

    const forecastFilterCategoriesFormated = forecastFilterCategories.map(category => {
        switch(category){
            case("Committed"):
                return {title: category, count: opportunitiesListCommited.length}     
            case("Expected"):
                return {title: category, count: opportunitiesListExpected.length}
            case("Upside"):
                return {title: category, count: opportunitiesListUpside.length}
            case("Pipeline"):
                return {title: category, count: opportunitiesListPipeline.length}   
        }
    })
    forecastFilterCategoriesFormated.push({title:"Uncategorized", count: opportunitiesList.length - opportunitiesListCommited.length - opportunitiesListExpected.length - opportunitiesListUpside.length - opportunitiesListPipeline.length})
    forecastFilterCategoriesFormated.push({title:"All", count: opportunitiesList.length})

    //uncategorized category calculation
    const categorizedIds = []
    opportunitiesListCommited.length && opportunitiesListCommited.filter(opp => categorizedIds.push(opp.id))
    opportunitiesListExpected.length && opportunitiesListExpected.filter(opp => categorizedIds.push(opp.id))
    opportunitiesListUpside.length && opportunitiesListUpside.filter(opp => categorizedIds.push(opp.id))
    opportunitiesListPipeline.length && opportunitiesListPipeline.filter(opp => categorizedIds.push(opp.id))

    const opportunitiesListUncategorized = opportunitiesList.filter(opp => !categorizedIds.includes(opp.id))

    
    const filteredByForecastCategory = (forcastType) => {
        switch(forcastType){
            case("Committed"):
                setEditedOrderList(opportunitiesListCommited)
                break
            case("Expected"):
                setEditedOrderList(opportunitiesListExpected)
                break
            case("Upside"):
                setEditedOrderList(opportunitiesListUpside)
                break
            case("Pipeline"):
                setEditedOrderList(opportunitiesListPipeline)
                break
            case("Uncategorized"):
                setEditedOrderList(opportunitiesListUncategorized)
                break
            case("All"):
                setEditedOrderList(opportunitiesList)
                break
        }
    }

    const sortByDate = (direction) => {
        if(editedOrderList){
            if(direction === "Old first"){
                setEditedOrderList([...editedOrderList].sort((a,b) => new Date(b.close_date) - new Date(a.close_date)))
            }else{
                setEditedOrderList([...editedOrderList].sort((a,b) => new Date(a.close_date) - new Date(b.close_date)))
            }
        }
    }

    const sortByAmount = (direction) => {
        if(editedOrderList){
            if(direction === "From lowest to highest"){
                setEditedOrderList([...editedOrderList].sort((a,b) => a.amount - b.amount))
            }else if(direction === "From highest to lowest"){
                setEditedOrderList([...editedOrderList].sort((a,b) => b.amount - a.amount))
            }else{
                filteredByForecastCategory(localStorage.getItem("forcastType") ? localStorage.getItem("forcastType") : "All")
            }
        }
    }

    const dateformating = (date) =>{
        let dateList = date.split("-").reverse()
        let result = dateList[1] + "/"
        let iteration = dateList.map((item, index) => {
            if(index !== 1){
                result += item;
                (index !== dateList.length - 1) && (result += "/");
            }
        })
        return result
    }

    useEffect(()=>{
        if(opportunitiesList.length){
            setEditedOrderList(opportunitiesList)
            filteredByForecastCategory(localStorage.getItem("forcastType") ? localStorage.getItem("forcastType") : "All")
        }
    }, [opportunitiesList])

    useEffect(() => {
        amountTypeSort && sortByAmount(amountTypeSort)
    }, [amountTypeSort])

    useEffect(() => {
        dateTypeSort && sortByDate(dateTypeSort)
    }, [dateTypeSort])

    useEffect(()=>{
        forcastType && filteredByForecastCategory(forcastType)
    },[forcastType])

    useEffect(() => {
        !showPreview && setShowMessagePreviewId(null)
    }, [filteredUser]);

    const setPopUpCordinates = () => {
        if (tableRef?.current?.offsetWidth) {
            const windowWidth = window.innerWidth;
            const windowHieght = window.innerHeight;
            const tableWidth = tableRef.current.offsetWidth;
            const tableHeight = tableRef.current.offsetHeight
            const tableScrrolBottom = tableRef.current.offsetTop;
            let stylesOblect = {}
            stylesOblect.right = "15px"
            if(windowHieght < (tableHeight + 492)){
                stylesOblect.bottom = "10px"
            }else{
                stylesOblect.top = `calc(${(windowHieght - 702 > 0) ? windowHieght : 702}px - 702px)`
            }
            stylesOblect.position = "fixed"
            setCustomStyles(stylesOblect);
        };
    }

    useLayoutEffect(() => {
            window.addEventListener('resize', setPopUpCordinates);
            setPopUpCordinates();
            return () => {
            window.removeEventListener('resize', setPopUpCordinates);
        };
    }, [tableRef]);

    const setForcastTypeWrapper = (type) => {
        localStorage.setItem("forcastType", type)
        setForcastType(type)
    }

    const setCurrentOppotunityWrapper = (company) => {
        setCurrentOppotunity({company})
        setChatUploadedMessages([])
        if(showMessagePreviewId && !(tableSizeParams.width < 961)){
            handleShowMessagePreview(company.id)
        }
    }

    

    return <>
        <table className = "table dealDashboardMainTable" ref = {tableRef}>
            <thead>
                <tr className = "firstHead">
                    <th><span>Forecast Category</span></th>
                    <th className = "mainTableFilter">
                        <DropdownInput 
                            data = {editedOrderList.length ? amountFilterCategories : []} 
                            type = "mainTableFilter" 
                            title = "Amount "
                            setCurrentItem = {setAmountTypeSort}
                        />
                    </th>
                    <th className = "mainTableFilter">
                        <DropdownInput 
                            data = {editedOrderList.length ? closeDateFilterCategories : []} 
                            type = "mainTableFilter" 
                            title = "Close date "
                            setCurrentItem = {setDateTypeSort} 
                        />
                    </th>
                    <th className = "nameThreeColorsCol"><span>Relationship Strength</span></th>
                    <th className = "nameThreeColorsCol urgency"><span>Urgency</span></th>
                    <th className = "nameThreeColorsCol"><span>Strategic Business Impact</span></th>
                </tr>
                <tr className = "secondHead">
                    <th className = "filterSecondHead">
                        <DropdownInput 
                            data = {forecastFilterCategoriesFormated} 
                            dropDownIcon = {ImgBuilder.forcastFilter} 
                            type = "forecast"
                            setCurrentItem = {setForcastTypeWrapper}
                        />
                    </th>
                    <th></th>
                    <th></th>
                    <th >
                        <div className = "scaleThreeColorsCol">
                            <div>low</div>
                            <div>mid</div>
                            <div>high</div>
                        </div>
                    </th>
                    <th className = "scaleThreeColorsCol">
                        <div className = "scaleThreeColorsCol">
                            <div>low</div>
                            <div>mid</div>
                            <div>high</div>
                        </div>
                    </th>
                    <th className = "scaleThreeColorsCol">
                        <div className = "scaleThreeColorsCol">
                            <div>low</div>
                            <div>mid</div>
                            <div>high</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {editedOrderList.length ? 
                    editedOrderList.map((company, index) => <>
                    <tr 
                        key = {index}
                        onClick = {() => setTimeout(setCurrentOppotunityWrapper(company), 6000)} 
                        className = {`${currentOppotunity?.company.id === company.id ? 
                        "activeOpportunity" : 
                        ((index < editedOrderList.length-1 && editedOrderList[index+1].id === currentOppotunity?.company.id) ?
                            "beforeActiveOpportunity":
                            "")}`}>
                        <td style = {{position: "relative"}}>
                            <div style = {{paddingRight:"40px"}}>
                                <div className = "companyBlackText">{company?.opp_company?.name ? company?.opp_company?.name : null}</div>
                                <div className = "companyDetails">{company?.name ? company?.name : null}</div>
                                <div className = "companyDetails">{company?.cases?.length ? company.cases.map(comp_case => comp_case.name) : null}</div>
                                <div className = "companyDetails">{company?.opp_product?.name ? company.opp_product.name : null}</div>
                            </div>
                            {
                                <div className="companyDetailsMessages">
                                    <ChatButton 
                                        company = {company}
                                        handleShowMessageModal = {handleShowMessageModal}
                                        handleShowMessagePreview = {handleShowMessagePreview} 
                                        chatsWithUnreadedState = {chatsWithUnreadedState}
                                    />
                                </div> 
                            }
                        </td>
                        <td className = "companyBlackText">{`$${company.amount.toLocaleString("en-US")}`}</td>
                        <td className = "companyCloseDate">{dateformating(company.close_date)}</td>
                        <td>
                            <div className = {`datathreeColors ${company.opportunity_relationships_strength}Color`}>
                                <div className = "levelDatathreeColors low"></div>
                                <div className = "levelDatathreeColors mid"></div>
                                <div className = "levelDatathreeColors high"></div>
                            </div>
                        </td>
                        <td>
                            <div className = {`datathreeColors ${company.opportunity_urgency}Color`}>
                                <div className = "levelDatathreeColors low"></div>
                                <div className = "levelDatathreeColors mid"></div>
                                <div className = "levelDatathreeColors high"></div>
                            </div>
                        </td>
                        <td>
                        <div className = {`datathreeColors ${company.opportunity_strategic_business_impact}Color`}>
                                <div className = "levelDatathreeColors low"></div>
                                <div className = "levelDatathreeColors mid"></div>
                                <div className = "levelDatathreeColors high"></div>
                            </div>
                        </td>
                    </tr>
                    </>) : 
                    <tr className = "titleNoData"><div>There are no opportunities related for this forecast</div></tr>}
            </tbody>
            <tfoot>
                <td className = "companyBlackText">Total {forcastType?.title ? forcastType?.title : forcastType}</td>
                <td className = "companyBlackText">{`$${editedOrderList.length ? totalCommited.toLocaleString("en-US") : "0,000"}`}</td>
            </tfoot>
        </table>
        {
           openBigModal || (tableSizeParams.width > 961 && showMessagePreviewId)  ?     
            <MessagesPreview
                customStyles = {customStyles}
                openBigModal={openBigModal}
                setOpenBigModal={setOpenBigModal}
                company={currentOppotunity}
                handleCloseMessagePreview = {handleCloseMessagePreview}
                draggable = {true}
                setOpenModalObject = {setOpenModalObject}
                openModalObject = {openModalObject}
                opportunityId = {showMessagePreviewId}
            /> : null
            
        }
        <MessagesModal
            openModal = {!openBigModal && openModalObject.show}
            opModal={openModalObject.show}
            opportunityId = {openModalObject?.opportunityId}
            withIcon = {false}
            setOpenModalObject = {setOpenModalObject}
            handleShowMessagePreview = {() => tableSizeParams.width < 961 ? false : handleShowMessagePreview(localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1))}
            type = "main"
        /> 
        <NeedToRefreshAlert tablewidth = {tableSizeParams.width} />
    </>
}

export default inject(
    "opportunitiesModel",
    "objectivesModel",
    "chatsModel",
    "authModel",
    "serverAPImodel",
    "userModel"
)(observer(DealDashboardMainTable))